import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../components/common/Logo';
import { LeftArrow } from '../svg';
import GoogleLogo from '../assets/google_logo.png';
import FacebookLogo from '../assets/facebook_logo.png';
import CheckIcon from '../assets/fi_check.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import PasswordInput from '../components/common/PasswordInput';

const SignupInfluencer = () => {
  const [values, setValues] = useState({
    email: '',
    password: '',
    role: 'user',
    authProvider: 'local',
  });
  const [otp, setOtp] = useState(''); // State for OTP input
  const [step, setStep] = useState(1); // Step 1 for Signup, Step 2 for OTP
  const apiUrl = process.env.REACT_APP_API_URL;
  const termsUrl = `${process.env.REACT_APP_WEBSITE_DOMAIN}/terms`;
  const navigate = useNavigate();

  const handleSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post(`${apiUrl}/auth/register`, values);
      window.fbq('track', 'Lead', {
        value: values.email,
        type: 'influencer',
        registrationStep: 'start',
      });
      setStep(2);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message ?? '';
      toast.error('An error occurred. Please try again. ' + errorMessage);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((previousValues) => ({
      ...previousValues,
      [name]: value,
    }));
  };

  const handleResendOTP = () => {
    console.log('OTP resent');
  };

  const handleOtpSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/auth/verifyOtp`, {
        email: values.email,
        token: otp,
      });

      window.fbq('track', 'CompleteRegistration', {
        value: values.email,
        type: 'influencer',
        registrationStep: 'success',
      });

      toast.success('Welcome to Linkfluencer');
      const { accessToken, refreshToken } = response.data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      navigate('/');
    } catch (err) {
      toast.error('An error occurred. Please try again.');
    }
  };

  const handleLoginRedirect = () => {
    navigate('/signin');
  };

  const handleBackRedirect = () => {
    navigate('/signup');
  };

  const handleGoogleSignin = async () => {
    window.fbq('track', 'SocialSignin', {
      provider: 'google',
      registrationStep: 'start',
      type: 'influencer',
    });
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
  };
  const handleFacebookSignin = async () => {
    window.fbq('track', 'SocialSignin', {
      provider: 'facebook',
      registrationStep: 'start',
      type: 'influencer',
    });
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/facebook`;
  };

  return (
    <div>
      <Logo />
      {step === 1 && (
        <div className="flex items-center justify-center min-h-screen bg-white">
          <div className="w-full max-w-md p-8 bg-white rounded-lg relative">
            <div className="flex items-center space-x-4 mb-4 relative md:right-2">
              <LeftArrow
                className="size-10 border p-2 rounded-full border-[#113E53] bg-[#59FF93] hover:rotate-45 duration-150"
                onClick={handleBackRedirect}
              />
              <div>
                <h1 className="text-4xl">Sign Up</h1>
                <p className="text-xl mt-2">Make everyday a pay day</p>
              </div>
            </div>
            <form onSubmit={handleSignup}>
              <input
                className="p-3 rounded-full border border-gray-500 m-2 w-full"
                type="text"
                value={values.email}
                name="email"
                onChange={handleChange}
                placeholder="Email"
                required
              />
              <PasswordInput
                className="p-3 rounded-full border border-gray-500 m-2 w-full"
                name="password"
                value={values.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
              <div className="flex gap-2 m-2 w-full">
                <input className="w-4" type="checkbox" required />
                <p>
                  Agreeing to our{' '}
                  <a
                    href={termsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    Terms and Policy
                  </a>
                </p>
              </div>
              <button
                type="submit"
                className="w-full p-3 m-2 cursor-pointer bg-[#020D3A] text-white rounded-full"
              >
                Create Account
              </button>
            </form>
            <div className="flex items-center my-4">
              <div className="flex-grow border-t border-gray-300"></div>
              <span className="px-4 text-gray-600">OR</span>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>
            <div className="md:flex items-center justify-center md:space-x-4 m-4">
              <button
                onClick={handleGoogleSignin}
                className="flex w-full gap-2 justify-center mb-2 md:mb-0 py-3 px-8 bg-white border rounded-full shadow-sm hover:shadow-md"
              >
                <img src={GoogleLogo} alt="Google" width={20} height={20} />
                <span className="font-bold">Google</span>
              </button>
              <button
                onClick={handleFacebookSignin}
                className="flex w-full justify-center gap-2 p-3 px-8 bg-white border rounded-full shadow-sm hover:shadow-md"
              >
                <img src={FacebookLogo} alt="Facebook" width={20} height={20} />
                <span className="font-bold">Facebook</span>
              </button>
            </div>
            <div className="w-full self-center flex justify-center m-2">
              <span className="mr-2">Already have an account?</span>
              <b
                className="text-navy hover:underline cursor-pointer text-[16px] font-[400] underline bg-transparent border-none p-0"
                onClick={handleLoginRedirect}
              >
                Login
              </b>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="flex items-center justify-center min-h-screen bg-white">
          <div className="w-full max-w-md p-4 md:p-8 bg-white rounded-lg relative">
            <div className="flex items-center space-x-4 mb-4 relative md:right-2">
              <LeftArrow
                className="size-10 border p-2 rounded-full border-[#113E53] bg-[#59FF93] hover:rotate-45 duration-150"
                onClick={() => setStep(1)}
              />
              <div>
                <h1 className="text-4xl">OTP Verification</h1>
                <p className="text-xl mt-2">
                  Enter the OTP you received by email.
                </p>
              </div>
            </div>
            <form onSubmit={handleOtpSubmit}>
              <input
                className="p-3 rounded-full border border-gray-500 m-2 w-full"
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter Email OTP"
                required
              />
              <div className="flex gap-2 m-2 w-full">
                <img src={CheckIcon} alt="check" width={20} height={20} />
                <p>OTP sent on your email successfully</p>
              </div>
              <p
                className="cursor-pointer relative left-9 "
                onClick={handleResendOTP}
              >
                Resend OTP
              </p>
              <button
                type="submit"
                className="w-full p-3 m-2 cursor-pointer bg-[#020D3A] text-white rounded-full"
              >
                Verify OTP
              </button>
            </form>
            <div className="flex justify-between items-center m-2">
              <div className="flex justify-center w-full p-2">
                <p className="mr-2">Already have an account?</p>
                <p
                  className="cursor-pointer font-bold"
                  onClick={handleLoginRedirect}
                >
                  Login
                </p>
              </div>
            </div>
            <div className="md:flex md:space-x-8 w-full justify-center m-2">
              <button className="flex w-full gap-2 py-3 px-8 bg-white border rounded-full shadow-sm hover:shadow-md">
                <img src={GoogleLogo} alt="Google" width={20} height={20} />
                <span className="font-bold">Google</span>
              </button>
              <button className="flex w-full gap-2 p-3 px-8 mb-2 md:mb-0 bg-white border rounded-full shadow-sm hover:shadow-md">
                <img src={FacebookLogo} alt="Facebook" width={20} height={20} />
                <span className="font-bold">Facebook</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignupInfluencer;
