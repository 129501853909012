import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../components/common/Logo';
import { LeftArrow } from '../svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AuthProvider } from '../types/enums';
import CheckIcon from '../assets/fi_check.png';
import PasswordInput from '../components/common/PasswordInput';

const SignupBrand = () => {
  const [values, setValues] = useState({
    brandName: '',
    companyEmail: '',
    password: '',
    role: 'brandUser',
    authProvider: 'local',
  });
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState(1);
  const apiUrl = process.env.REACT_APP_API_URL;
  const termsUrl = `${process.env.REACT_APP_WEBSITE_DOMAIN}/terms`;
  const navigate = useNavigate();

  const handleSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post(`${apiUrl}/auth/register`, {
        brandName: values.brandName,
        password: values.password,
        role: values.role,
        authProvider: AuthProvider.Local,
        email: values.companyEmail,
      });
      window.fbq('track', 'Lead', {
        value: values.companyEmail,
        type: 'brand',
        registrationStep: 'start',
      });
      setStep(2);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message ?? '';
      toast.error('An error occurred. Please try again. ' + errorMessage);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((previousValues) => ({
      ...previousValues,
      [name]: value,
    }));
  };

  const handleResendOTP = () => {
    console.log('OTP resent');
  };

  const handleOtpSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/auth/verifyOtp`, {
        email: values.companyEmail,
        token: otp,
      });

      window.fbq('track', 'CompleteRegistration', {
        value: values.companyEmail,
        type: 'brand',
        registrationStep: 'success',
      });

      toast.success('Welcome to Linkfluencer');
      const { accessToken, refreshToken } = response.data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      navigate('/');
    } catch (err) {
      toast.error('An error occurred. Please try again.');
    }
  };

  const handleLoginRedirect = () => {
    navigate('/signin');
  };

  const handleBackRedirect = () => {
    navigate('/signup');
  };

  return (
    <div>
      <Logo />
      {step === 1 && (
        <div className="flex items-center justify-center min-h-screen bg-white">
          <div className="w-full max-w-md p-8 bg-white rounded-lg relative">
            <div className="flex items-center space-x-4 mb-4 relative md:right-2">
              <LeftArrow
                className="size-10 border p-2 rounded-full border-[#113E53] bg-[#59FF93] hover:rotate-45 duration-150"
                onClick={handleBackRedirect}
              />
              <div>
                <h1 className="text-4xl">Sign Up</h1>
                <p className="text-xl mt-2">Take your brand Public</p>
              </div>
            </div>
            <form onSubmit={handleSignup}>
              <input
                className="p-3 rounded-full border border-gray-500 m-2 w-full"
                type="text"
                value={values.brandName}
                name="brandName"
                onChange={handleChange}
                placeholder="Brand Name"
                required
              />
              <input
                className="p-3 rounded-full border border-gray-500 m-2 w-full"
                type="email"
                value={values.companyEmail}
                name="companyEmail"
                onChange={handleChange}
                placeholder="Work Email"
                required
              />
              <PasswordInput
                className="p-3 rounded-full border border-gray-500 m-2 w-full"
                name="password"
                value={values.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
              <div className="flex gap-2 m-2 w-full">
                <input className="w-4" type="checkbox" required />
                <p>
                  Agreeing to our{' '}
                  <a
                    href={termsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    Terms and Policy
                  </a>
                </p>
              </div>
              <button
                type="submit"
                className="w-full p-3 m-2 cursor-pointer bg-[#020D3A] text-white rounded-full"
              >
                Create Account
              </button>
            </form>
            <div className="w-full self-center flex justify-center m-2">
              <span className="mr-2">Already have an account?</span>
              <b
                className="text-navy hover:underline cursor-pointer text-[16px] font-[400] underline bg-transparent border-none p-0"
                onClick={handleLoginRedirect}
              >
                Login
              </b>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="flex items-center justify-center min-h-screen bg-white">
          <div className="w-full max-w-md p-4 md:p-8 bg-white rounded-lg relative">
            <div className="flex items-center space-x-4 mb-4 relative md:right-2">
              <LeftArrow
                className="size-10 border p-2 rounded-full border-[#113E53] bg-[#59FF93] hover:rotate-45 duration-150"
                onClick={() => setStep(1)} // Go back to signup step
              />
              <div>
                <h1 className="text-4xl">OTP Verification</h1>
                <p className="text-xl mt-2">
                  Enter the OTP sent to your device for secure access
                </p>
              </div>
            </div>
            <form onSubmit={handleOtpSubmit}>
              <input
                className="p-3 rounded-full border border-gray-500 m-2 w-full"
                type="text" // Change type to text for OTP input
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter Email OTP"
                required
              />
              <div className="flex gap-2 m-2 w-full">
                <img src={CheckIcon} alt="check" width={20} height={20} />
                <p>OTP sent on your device successfully</p>
              </div>
              <p
                className="cursor-pointer relative left-9 "
                onClick={handleResendOTP}
              >
                Resend OTP
              </p>
              <button
                type="submit"
                className="w-full p-3 m-2 cursor-pointer bg-[#020D3A] text-white rounded-full"
              >
                Verify OTP
              </button>
            </form>
            <div className="flex justify-between items-center m-2">
              <div className="flex justify-center w-full p-2">
                <p className="mr-2">Already have an account?</p>
                <p
                  className="cursor-pointer font-bold"
                  onClick={handleLoginRedirect}
                >
                  Login
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignupBrand;
